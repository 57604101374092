<template>
  <!-- Filter -->
  <AppDropdown right>
    <template v-slot:dropdown-button>
      <AppBtn color="gray-light-3" class="p-1">
        <div class="d-flex align-items-center text-body-3">
          <AppIcon name="settings_adjust"></AppIcon>
          <span class="ml-2 d-none d-md-block">Filter</span>
        </div>
      </AppBtn>
    </template>
    <template v-if="items" v-slot:dropdown-menu>
      <div
        v-for="(item, index) in items"
        :key="`filter-item-${index}`"
        class="dropdown-item d-flex align-items-center"
      >
        <AppInputCheckbox
          v-model="innerVal[item.value]"
          hideError
        ></AppInputCheckbox>
        <span>{{ item.text }}</span>
      </div>
    </template>
  </AppDropdown>
</template>

<script>
import AppDropdown from '@/shared/elements/AppDropdown.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';

export default {
  name: 'FilterBtn',

  components: { AppDropdown, AppBtn, AppInputCheckbox, AppIcon },

  mixins: [_appDefaultInput],

  props: {
    items: { type: Array, default: () => [] },
  },

  data() {
    return {
      innerVal: this.items[0],
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <component :is="layout">
    <template v-slot:title>Notification</template>
    <div
      class="notification-list p-1 p-md-3 m-auto d-block h-100 overflow-auto"
    >
      <div v-if="false" class="p-2 d-flex justify-content-end">
        <FilterBtn></FilterBtn>
      </div>
      <!-- Notification Items -->
      <div class="notification-drawer d-flex flex-column">
        <div class="notification-drawer-content flex-shrink-1">
          <div
            v-for="(notification, index) in notifications"
            :key="`notification-list-${index}`"
          >
            <p
              class="
                m-0
                p-2
                bg-gray-light-4
                text-uppercase
                font-style-secondary
              "
            >
              {{ timePassedCal(notification.date).datePassed }}
            </p>

            <div
              v-for="(notif_item, notifIndex) in notification.notifications"
              :key="`notification-item-${notif_item.id}-${notifIndex}`"
              class="notification-drawer-content-item p-2 row"
            >
              <!-- profile image -->
              <div v-if="false" class="col-3 d-flex justify-content-center">
                <AppMediaViewer
                  width="75%"
                  aspectRatio="1/1"
                  class="rounded-circle"
                ></AppMediaViewer>
              </div>
              <!-- description -->
              <div class="offset-1 col-9">
                <p class="text-body-3" v-html="notif_item.message"></p>
                <p class="text-caption text-gray m-0">
                  {{ timePassedCal(notif_item.rawDate).timePassedString }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <AppBtn
              v-if="!lastNotification"
              text
              class="mt-3 w-100"
              :loading="loading"
              @click="fetchNotifs"
            >
              See more...
            </AppBtn>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import FilterBtn from '@/components/Partial/FilterBtn.vue';
import timePassedCal from '@/shared/utils/timePassedCalculator';
import Notifications from '@/shared/api/Notifications';
import { DateTime } from 'luxon';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';

export default {
  name: 'NotificationList',

  components: {
    FilterBtn,
    AppMediaViewer,
    AppBtn,
    LayoutVendor: () =>
      import('@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue'),
    LayoutBuyer: () =>
      import('@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue'),
    LayoutSuperadmin: () =>
      import(
        '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue'
      ),
  },

  data() {
    return {
      date: '',
      notifications: [],
      page: 1,
      totalNotifs: 0,
      lastNotification: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
    layout() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'LayoutSuperadmin';
        case PersonaConstants.vendor.value:
          return 'LayoutVendor';
        case PersonaConstants.buyer.value:
        default:
          return 'LayoutBuyer';
      }
    },
  },

  methods: {
    timePassedCal,
    async fetchNotifs() {
      this.loading = true;
      const response = await Notifications.getNotifications({
        params: { page: this.page },
      });
      this.loading = false;

      const data = response.data.data;

      if (data.length > 0) {
        this.$emit('hasNotification');
      }
      data.forEach((notif) => {
        const notification = notif;
        notification.rawDate = DateTime.fromISO(notif.created_at).toFormat(
          'yyyy-MM-dd HH:mm:ss'
        );
        const date = DateTime.fromISO(notif.created_at).toFormat('DDD');
        const existingNotifIndex = this.notifications.findIndex(
          (existNotif) => existNotif.date === date
        );
        if (existingNotifIndex >= 0) {
          this.notifications[existingNotifIndex].notifications.push(
            notification
          );
        } else {
          this.notifications.push({
            date: date,
            notifications: [notification],
          });
        }
      });
      if (response.data.last_page === this.page) {
        this.lastNotification = true;
      } else {
        this.page += 1;
      }
    },
  },
  mounted() {
    this.fetchNotifs();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';

.notification-list {
  max-width: 800px;

  .notification-drawer {
    width: 100%;
    &-content {
      overflow: hidden;
      &-item:hover {
        @extend .bg-gray-light-4;
      }
    }
  }
}
</style>
